import type { NumberFormatProps } from "react-number-format"
import NumberFormat from "react-number-format"
import { forwardRef } from "react"
import { Field } from "./Field"
import { FieldProps } from "../../../types/types"
import { FORMAT_PHONE, getPhoneWithOutCode } from "../../../validations/phone"

export const PhoneField = forwardRef<
  HTMLInputElement,
  Pick<
    FieldProps,
    | "errorMessage"
    | "disabled"
    | "required"
    | "name"
    | "withButton"
    | "iconButton"
    | "onClickButton"
  > &
    Pick<NumberFormatProps, "value" | "onChange" | "onValueChange">
>(({ required = false, name = "phone", value, ...props }, ref) => {
  return (
    <>
      <NumberFormat
        {...props}
        required={required}
        name={name}
        withAnimatingLabel
        placeholder={"Номер телефона"}
        format={FORMAT_PHONE}
        mask="_"
        allowEmptyFormatting={true}
        customInput={Field}
        type={"tel"}
        value={getPhoneWithOutCode(!value ? "" : value.toString())}
        getInputRef={ref}
        autoComplete={"tel"}
      />
    </>
  )
})

PhoneField.displayName = "PhoneField"
